import { Banner } from '@packages/cms-components/src/modules/Banner/Banner';
import type { CmsDataJsonApi } from '@packages/cms-components/interfaces';
import { Box, DynamicYield, ErrorBoundary } from '@packages/shared';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import type { FC } from 'react';

export type CmsTopBannerProps = {
  bannerData: CmsDataJsonApi;
};

/**
 * CmsTopBanner component description displayed in storybook
 * */
export const CmsTopBanner: FC<CmsTopBannerProps> = ({ bannerData }) => {
  const { isDesktop } = useDeviceType();
  const { dynamicYieldEnabled } = useConfig();

  return (
    <Box
      sx={{
        img: {
          maxWidth: '100%',
        },
      }}
    >
      {dynamicYieldEnabled && (
        <ErrorBoundary>
          <DynamicYield dataDyId={`mba_topbanner-services-${isDesktop ? 'desktop' : 'mobile'}`} />
          <DynamicYield dataDyId={`mba_campaign-banner-${isDesktop ? 'desktop' : 'mobile'}`} />
        </ErrorBoundary>
      )}
      <ErrorBoundary>
        <Banner bannerData={bannerData} />
      </ErrorBoundary>
    </Box>
  );
};
