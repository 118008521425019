import { useRef, useState } from 'react';

import type { SelectedFilterValue } from '../../types';
import { isNotEmpty, areNotEquivalent } from '../../utils/selectedFilterValues';

/** Represents the state and logic for having a filter selection popover and selecting a filter in the popover */
export function useFilterSettingsPopover(
  initiallySelectedFilterValues: SelectedFilterValue[] = [],
) {
  const [selectedFilterId, setSelectedFilterId] = useState<string>();

  const isPopoverOpen = !!selectedFilterId;

  const [selectedFilterValues, setSelectedFilterValues] = useState<SelectedFilterValue[]>(
    initiallySelectedFilterValues,
  );
  const popoverAnchor = useRef<HTMLElement>(undefined);

  const selectFilterValues = (value: SelectedFilterValue) => {
    setSelectedFilterValues((current) =>
      current
        .filter((x) => x.filterId !== value.filterId)
        .concat(value)
        .filter(isNotEmpty),
    );
  };

  const closePopover = () => {
    setSelectedFilterId(undefined);
  };

  const selectFilter = (filterId: string, anchor: HTMLElement) => {
    if (filterId === selectedFilterId) {
      closePopover();
      return;
    }

    setSelectedFilterValues(initiallySelectedFilterValues);
    popoverAnchor.current = anchor;
    setSelectedFilterId(filterId);
  };

  const activeFilterIds = initiallySelectedFilterValues.filter(isNotEmpty).map((x) => x.filterId);

  const hasChanges = areNotEquivalent(initiallySelectedFilterValues, selectedFilterValues);

  return {
    activeFilterIds,
    selectedFilterId,
    selectFilter,
    selectedFilterValues,
    hasChanges,
    selectFilterValues,
    popoverAnchor,
    isPopoverOpen,
    closePopover,
  };
}
