import { defineMessages, useIntl } from 'react-intl';

import type { FragmentType } from '@packages/gql/generated/shopping';
import type { EfficiencyFlagType } from '@packages/gql/generated/shopping/graphql';
import { unmask } from '@packages/gql/src/betterMasking';
import type { MapEfficiencyFlagsFragmentFragmentDoc } from '@packages/gql/generated/shopping/MapEfficiencyFlagsFragmentFragmentDoc';
import type { ProductCardSpecificProps } from '@packages/modules/src/ProductCard/types';
import type { EfficiencyFlagProps } from '@packages/shared/src/components/EfficiencyFlag/EfficiencyFlag';

/* GraphQL */ `
  fragment MapEfficiencyFlagsFragment on EfficiencyFlag {
    type
    text
    legacyColor
    details {
      description
      image
    }
    datasheet
  }
`;

const labelColorsMap = {
  A: 'darkgreen',
  B: 'mediumgreen',
  C: 'lightgreen',
  D: 'yellow',
  E: 'lightorange',
  F: 'darkorange',
  G: 'red',
} as const;

const validColors = Object.values(labelColorsMap);
const isValidColor = (
  color: string,
): color is (typeof labelColorsMap)[keyof typeof labelColorsMap] =>
  validColors.includes(color as (typeof validColors)[number]);

const efficiencyFlagTypeMap: Record<EfficiencyFlagType, EfficiencyFlagProps['type']> = {
  ENERGY: 'energyEfficiency',
  ENERGY_OLD: 'energyEfficiencyOld',
  FUEL: 'fuelEfficiency',
  EXTERNAL_ROLLING_NOISE: 'externalRollingNoise',
  WET_SURFACE_GRIP: 'wetGrip',
};

const messages = defineMessages({
  datasheetLabel: {
    id: 'search.productCard.datasheet',
    defaultMessage: 'Produktdatenblatt',
    description: 'Text for the clickable link in a card that opens the datasheet in a modal.',
  },
});

export const useMappedEfficiencyFlags = (
  maskedFlags: FragmentType<typeof MapEfficiencyFlagsFragmentFragmentDoc>[] | null,
): ProductCardSpecificProps['efficiencyFlags'] => {
  const flags = unmask<typeof MapEfficiencyFlagsFragmentFragmentDoc>(maskedFlags);

  const { formatMessage } = useIntl();

  // `ProductCard` currently does not handle empty arrays well, it needs to be `undefined` to fully hide relevant child components
  if (flags?.length === 0) return undefined;

  return (
    flags?.map(({ type, text, legacyColor, details, datasheet }) => ({
      type: efficiencyFlagTypeMap[type],
      text,
      labelColor:
        type === 'ENERGY_OLD' && legacyColor && isValidColor(legacyColor)
          ? legacyColor
          : labelColorsMap[text as keyof typeof labelColorsMap], // undefined if invalid color, which is fine as the labelColor is optional
      overlay: details ? { title: details.description, imgUrl: details.image } : undefined,
      datasheet: datasheet
        ? { url: datasheet, name: formatMessage(messages.datasheetLabel) }
        : undefined,
    })) ?? undefined
  );
};
