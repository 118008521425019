'use client';

import type { FC } from 'react';
import { Box } from '@packages/shared';
import { ScrollTopButton } from '@packages/modules/src/ScrollTopButton/ScrollTopButton';

/**
 * Scroll to top button for search pages (MBA, SERP and ZeroResultsPage)
 * */
export const SearchScrollTopButton: FC = () => (
  <Box
    className="scroll-to-top-button"
    sx={{
      position: 'fixed',
      bottom: '1rem',
      right: '2%',
      zIndex: 'appBar',
    }}
  >
    <ScrollTopButton />
  </Box>
);
