/* eslint-disable @typescript-eslint/no-unused-expressions */
import type { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next';
import { parseCookies } from 'nookies';

import config, { getFullLocale } from '@packages/config';
import { localizedPathnameCacheKey } from '@packages/config/src/default';
import { ErrorBoundary, HeadMeta } from '@packages/shared';
import { useI18n } from '@packages/shared/src/hooks/useI18n/useI18n';
import { getLanguageFromParams } from '@packages/shared/src/utils/getLanguageFromParams/getLanguageFromParams';
import { localizeUrl } from '@packages/shared/src/utils/localizeUrl/localizeUrl';
import { normalizeInternalPathname } from '@packages/shared/src/utils/normalizeInternalPathname/normalizeInternalPathname';
import { splitUrl } from '@packages/shared/src/utils/splitUrl/splitUrl';
import type { CmsDataJsonApi } from '@packages/cms-components/interfaces/jsonapi';
import { getEcState } from '@packages/shared/src/utils/ecState/ecState';
import { logger } from '@packages/shared/src/utils/logger/logger';
import { useConfig } from '@packages/shared/src/hooks/useConfig/useConfig';
import type { CmsData } from '@packages/cms-components/interfaces';
import { createUrqlServerClient } from '@packages/gql/src/urql';

import { getTopLevelCategories } from '@packages/search/src/utils/getTopLevelCategories';
import { startChildLogger } from '@packages/search/src/utils/pages/startChildLogger';
import { extractRequiredData } from '@packages/search/src/utils/pages/extractRequiredData';
import { handleSpecialResponses } from '@packages/search/src/utils/pages/handleSpecialResponses';
import { hasValidationErrors } from '@packages/search/src/utils/pages/hasValidationErrors';
import { logGraphqlErrors } from '@packages/search/src/utils/pages/logGraphqlErrors';
import { usePersonalizationCookie } from '@packages/search/src/hooks/usePersonalizationCookie';
import { useAbTestingCookiesFromEps } from '@packages/search/src/hooks/useAbTestingCookiesFromEps';
import { useRememberedPagedata } from '@packages/shared/src/hooks/useScrollRestoration/useScrollRestoration';
import {
  getSharedServerSideGqlVariables,
  useSharedClientSideGqlVariables,
} from '@packages/search/src/utils/gqlVariables';
import { useCleanPathname } from '@packages/search/src/hooks/useCleanPathname';
import { CategoryPageBrandOverview } from '@packages/search/src/components/CategoryPageBrandOverview/CategoryPageBrandOverview';
import { CategoryPageCmsContentOnly } from '@packages/search/src/components/CategoryPageCmsContentOnly/CategoryPageCmsContentOnly';
import { CategoryPageDefaultContent } from '@packages/search/src/components/CategoryPageDefaultContent/CategoryPageDefaultContent';
import { CategoryPageLoadingFallback } from '@packages/search/src/components/CategoryPageLoadingFallback/CategoryPageLoadingFallback';
import { SearchDebugPanelDynamic } from '@packages/search/src/components/SearchDebugPanel/SearchDebugPanel.dynamic';
import { SearchScrollTopButton } from '@packages/search/src/components/SearchScrollTopButton/SearchScrollTopButton';
import { Tracking } from '@packages/search/src/components/Tracking/Tracking';

import { CategoryPageDataDocument } from '@packages/gql/generated/shopping/CategoryPageDataDocument';
import { promotionBannerDataFetcher } from '@packages/cms-components/src/modules/PromotionBanner/utils/fetch/promotionBannerDataFetcher';
import { DynamicNewsletterSheet } from '@packages/cms-components/src/components/Forms/NewsletterSheet/NewsletterSheet.dynamic';
import { Survey } from '@packages/cms-components/src/modules/Survey';
import { cmsFetcher } from '@packages/cms-components/src/utils/cmsFetcher';
import { getMainAudience } from '@packages/cms-components/src/utils/audiences';
import { OnlyInDebugMode } from '@packages/shared/src/components/OnlyInDebugMode/OnlyInDebugMode';

/* GraphQL */ `
  query CategoryPageData($params: SearchParametersInput!, $pathname: String!, $locale: String!) {
    category(params: $params, pathname: $pathname) {
      __typename
      ...CategoryPageDefaultContentFragment
      ...UseAbTestingCookiesFromEpsFragment
      ...CategoryPageCmsContentOnlyFragment
      ...CategoryPageBrandOverviewFragment
      ...SearchPageTrackingFragment
      ...SearchPageTrackingCategoryPageFragment
      ...SearchDebugPanelFragment
      ...SearchHandleSpecialResponsesFragment

      ... on ProperCategoryQueryResult {
        currentCategoryId
        alternateLocalizedLinks {
          language
          pathname
        }
      }

      ... on CategoryResult {
        cmsContentPosition
      }

      ... on SearchResultBase {
        id
      }
    }

    seoForPath(pathname: $pathname, locale: $locale) {
      ...ProductListSeoFragment
      title
      description
      keywords
      robots
      canonicalUrl
    }
  }
`;

type CategoryPageProps = Omit<
  InferGetServerSidePropsType<typeof getServerSideProps>,
  'fallback' | 'urqlState'
>;

/**
 * Page for displaying all products in a product category
 * */
export const CategoryPage: NextPage<CategoryPageProps> = ({
  searchGqlData: data,
  topLevelCategories,
  bannerData,
  cmsContent,
  referrer,
}) => {
  const cleanPathname = useCleanPathname();

  const { locale } = useI18n();

  const variables = {
    ...useSharedClientSideGqlVariables(referrer),
    pathname: cleanPathname,

    // seo variables
    locale,
  };

  usePersonalizationCookie();
  useAbTestingCookiesFromEps(
    data && 'id' in data.category && data.category.id ? data.category : undefined,
  );
  useRememberedPagedata();

  const seoResponse = data?.seoForPath;

  const { title, description, keywords, robots, canonicalUrl } = seoResponse ?? {};

  const {
    host: { domain },
  } = useConfig();

  // no canonicallink from SEO API response -> use current url without params as canonical
  const canonicallink = canonicalUrl || `https://www.${domain}${cleanPathname}`.toLowerCase();

  const seoInformationExists = !!seoResponse;

  if (data?.category.__typename === 'LandingPageResult' && !cmsContent) {
    logger.error('No CMS content for SIS page');
  }

  const alternateLinks =
    data && 'alternateLocalizedLinks' in data.category
      ? Object.fromEntries(
          data.category.alternateLocalizedLinks.map((link) => [link.language, link.pathname]),
        )
      : undefined;

  return (
    <>
      {/* <Tracking /> needs to be first component in page render flow because of timing issues with glycerin tracking */}
      {data && <Tracking maskedResult={data.category} maskedCategoryPageResult={data.category} />}

      {seoInformationExists && (
        <HeadMeta
          meta={{
            title,
            ...(description ? { description } : {}),
            ...(keywords ? { keywords } : {}),
            robots,
          }}
          links={{ canonical: canonicallink, alternateLinks }}
        />
      )}

      {!data && <CategoryPageLoadingFallback />}

      {data?.category.__typename === 'LandingPageResult' && cmsContent && (
        <CategoryPageCmsContentOnly
          cmsContent={cmsContent}
          maskedSeoResponse={seoResponse}
          maskedData={data.category}
        />
      )}

      {data?.category.__typename === 'BrandsResult' && (
        <CategoryPageBrandOverview
          cmsContent={cmsContent}
          maskedSeoResponse={seoResponse}
          maskedResult={data.category}
          referrer={referrer}
        />
      )}

      {data?.category.__typename === 'CategoryResult' && (
        <CategoryPageDefaultContent
          maskedData={data.category}
          topLevelCategories={topLevelCategories}
          maskedSeoResponse={seoResponse}
          bannerData={bannerData}
          cmsContent={cmsContent}
          referrer={referrer}
        />
      )}

      <SearchScrollTopButton />

      <OnlyInDebugMode>
        <SearchDebugPanelDynamic
          maskedCategoryResult={
            data && 'currentCategoryId' in data.category ? data.category : undefined
          }
          document={CategoryPageDataDocument}
          data={data}
          variables={variables}
        />
      </OnlyInDebugMode>

      <ErrorBoundary fallback={<span data-testid="newslettersheet-error" />}>
        <DynamicNewsletterSheet />
      </ErrorBoundary>

      <ErrorBoundary>
        <Survey />
      </ErrorBoundary>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default CategoryPage;

export const getServerSideProps = (async (context) => {
  const { childLogger, getElapsedMilliseconds } = startChildLogger('CategoryPage', context);

  const { query, resolvedUrl, req } = context;

  const language = getLanguageFromParams(query, config);
  const { pathname } = splitUrl(resolvedUrl);

  const categoryUri = localizeUrl(
    normalizeInternalPathname(pathname, { trailingSlash: true }),
    language,
    config,
  );

  const locale = getFullLocale(language);
  const cookies = parseCookies(context);
  const referrer = req.headers.referer;

  childLogger.trace(getElapsedMilliseconds(), 'starting fetch from Search APIs (EPS + SEO)');

  const { ssrClient } = createUrqlServerClient();

  const variables = {
    ...getSharedServerSideGqlVariables(query, locale, req.headers, cookies, referrer),
    pathname: categoryUri,

    // seo variables
    locale,
  };

  const searchGqlQuery = ssrClient.query(CategoryPageDataDocument, variables).toPromise();

  const [searchGqlQueryResult] = await Promise.allSettled([searchGqlQuery]);

  childLogger.trace(getElapsedMilliseconds(), 'completed fetch from Search APIs (EPS + SEO)');

  logGraphqlErrors(searchGqlQueryResult, {
    childLogger,
    document: CategoryPageDataDocument,
    variables,
  });

  if (hasValidationErrors(searchGqlQueryResult)) {
    return { notFound: true }; // Better would be to return a 400 status code, but Next.js does not support that easily. This way, at least it's not a 5xx code.
  }

  const searchGqlData = extractRequiredData(searchGqlQueryResult, {
    childLogger,
    document: CategoryPageDataDocument,
    variables,
  });

  const { specialResponse } = handleSpecialResponses({
    maskedResult: searchGqlData.category,
    context,
    childLogger,
    getElapsedMilliseconds,
  });

  if (specialResponse) return specialResponse;

  // NOTE in specific cases with CMS content and no nav sidebar, `result` is missing altogether
  const categoryId =
    'currentCategoryId' in searchGqlData.category
      ? searchGqlData.category.currentCategoryId
      : undefined;

  childLogger.trace(
    getElapsedMilliseconds(),
    'starting fetch from Inspire APIs (external content + banner + promo banner',
  );

  const shouldFetchCmsContent =
    searchGqlData.category.__typename === 'LandingPageResult' ||
    searchGqlData.category.__typename === 'BrandsResult' ||
    (searchGqlData.category.__typename === 'CategoryResult' &&
      searchGqlData.category.cmsContentPosition !== 'HIDDEN');

  const [cmsContentPromise, bannerDataPromise, promotionBannerPromise] = await Promise.allSettled([
    shouldFetchCmsContent
      ? cmsFetcher<CmsData>({
          bucketUrl: config.staticContent.apiEndpoints.bucket,
          pathname: categoryUri,
          locale,
        })
      : null,
    cmsFetcher<CmsDataJsonApi>({
      bucketUrl: config.banner.apiEndpoints.bucket,
      locale,
      pathname: categoryId ?? '',
    }),
    promotionBannerDataFetcher(
      { ...query, ...{ shopId: categoryId } },
      req.cookies,
      context,
      locale,
    ),
  ]);

  childLogger.trace(
    getElapsedMilliseconds(),
    'completed fetch from Inspire APIs (external content + banner + promo banner',
  );

  if (cmsContentPromise.status === 'rejected') {
    childLogger.debug(
      { description: 'CMS Content Fetch (Categories-Page)', categoryUri },
      'Could not fetch cms content',
      cmsContentPromise.reason,
    );
  }

  if (bannerDataPromise.status === 'rejected') {
    childLogger.debug(
      { description: 'Banner Data Fetch (Categories-Page)', categoryId },
      'Could not fetch banner data',
      bannerDataPromise.reason,
    );
  }

  if (promotionBannerPromise.status === 'rejected') {
    childLogger.debug(
      { description: 'Promotion Banner Data Fetch (Categories-Page)', categoryId },
      'Could not fetch promo banner data',
      promotionBannerPromise.reason,
    );
  }

  const audience = req.cookies ? getMainAudience(getEcState(req.cookies)) : 'anonymous';
  const promotionBannerCacheKey = `promotionBannerData-${language}-${audience}`;

  childLogger.trace(getElapsedMilliseconds(), 'retrieving L1 categories');

  const topLevelCategories = getTopLevelCategories();

  const localizedAlternatePathname =
    'alternateLocalizedLinks' in searchGqlData.category
      ? searchGqlData.category.alternateLocalizedLinks[0]?.pathname
      : undefined;

  childLogger.trace(getElapsedMilliseconds(), 'finished, returning props');

  return {
    props: {
      searchGqlData,
      fallback: {
        ...(promotionBannerPromise.status === 'fulfilled' && {
          [promotionBannerCacheKey]: promotionBannerPromise.value,
        }),
        ...(localizedAlternatePathname && {
          // NOTE: currently only a single alternate URL is supported for the locale switcher,
          // so even if there are multiple alternates, we only use the first one
          [localizedPathnameCacheKey]: localizedAlternatePathname,
        }),
      } as Record<string, any>,
      ...(topLevelCategories && { topLevelCategories }),
      ...(cmsContentPromise.status === 'fulfilled' &&
        cmsContentPromise.value && {
          cmsContent: cmsContentPromise.value,
        }),
      ...(bannerDataPromise.status === 'fulfilled' &&
        bannerDataPromise.value && {
          bannerData: bannerDataPromise.value,
        }),
      ...(referrer && { referrer }),
    },
  };
}) satisfies GetServerSideProps;
