'use client';

import type { FC } from 'react';
import { Grid, ErrorBoundary } from '@packages/shared';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import type { CmsData } from '@packages/cms-components/interfaces';

import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { CategoryPageCmsContentOnlyFragmentFragmentDoc } from '@packages/gql/generated/shopping/CategoryPageCmsContentOnlyFragmentFragmentDoc';
import type { ProductListSeoFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductListSeoFragmentFragmentDoc';
import { ContentExternal } from '@packages/cms-components/src/modules/ContentExternal/ContentExternal';
import { ProductListSeo } from '../ProductListSeo/ProductListSeo';
import { ProductCategoryNavigationDesktop } from '../ProductCategoryNavigationDesktop/ProductCategoryNavigationDesktop';

/* GraphQL */ `
  fragment CategoryPageCmsContentOnlyFragment on LandingPageResult {
    LandingPageResultCategoryItems: categories {
      ...ProductCategoryNavigationDesktopFragment
    }
  }
`;

export type CategoryPageCmsContentOnlyProps = {
  /** the cms content to display */
  cmsContent: CmsData;
  /** the category navigation masked data */
  maskedData: FragmentType<typeof CategoryPageCmsContentOnlyFragmentFragmentDoc>;
  /** the search seo api response */
  maskedSeoResponse?: FragmentType<typeof ProductListSeoFragmentFragmentDoc>;
};

/**
 * Page content for NoProducts CMS page
 * */
export const CategoryPageCmsContentOnly: FC<CategoryPageCmsContentOnlyProps> = ({
  cmsContent,
  maskedData,
  maskedSeoResponse,
}) => {
  const data = unmask<typeof CategoryPageCmsContentOnlyFragmentFragmentDoc>(maskedData);
  const LandingPageResultCategoryItems = data?.LandingPageResultCategoryItems;
  const { isDesktop } = useDeviceType();

  const showLeftNavigation =
    isDesktop && LandingPageResultCategoryItems && LandingPageResultCategoryItems.length > 0;

  return (
    <Grid container spacing={0} padding={0} columns={5}>
      {showLeftNavigation && (
        <Grid item lg={1}>
          <ProductCategoryNavigationDesktop maskedItems={LandingPageResultCategoryItems} />
        </Grid>
      )}
      <Grid item lg={showLeftNavigation ? 4 : 5} {...(!showLeftNavigation && { width: '100%' })}>
        <ErrorBoundary>
          <ContentExternal data={cmsContent} />
        </ErrorBoundary>

        {maskedSeoResponse && <ProductListSeo maskedData={maskedSeoResponse} />}
      </Grid>
    </Grid>
  );
};
