import type { FC } from 'react';

import { Grid } from '@packages/shared';

import type { DesktopDisplayMode } from '../../types';
import { ProductGridSkeletonItem } from '../ProductGridSkeletonItem/ProductGridSkeletonItem';

import { getGridColumns } from '../ProductGrid/getGridColumns';
import { getCardSize } from '../ProductGrid/getCardSize';

export type ProductGridSkeletonProps = {
  /**
   * product card layout. Used to determine number of columns.
   *
   * @default 'cards'
   */
  displayMode: DesktopDisplayMode;
  /**
   * number of skeletons to display
   *
   * @default 72
   */
  count?: number;
};

/**
 * Display skeleton when products are loading instead of product cards
 */
export const ProductGridSkeleton: FC<ProductGridSkeletonProps> = ({
  displayMode = 'cards',
  count = 72,
}) => {
  const columns = getGridColumns(displayMode);
  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={columns}
      component="ul"
      sx={{
        listStyle: 'none',
        paddingLeft: 0,
      }}
    >
      {[...new Array(count)].map((_, index) => (
        <Grid
          item
          // eslint-disable-next-line react/no-array-index-key
          key={`skeleton_${index}`}
          {...getCardSize(columns, displayMode)}
          component="li"
        >
          <ProductGridSkeletonItem />
        </Grid>
      ))}
    </Grid>
  );
};
