import { useTheme, useMediaQuery } from '@mui/material';

export const useCurrentMuiBreakpoint = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));
  let deviceSize: 'xs' | 'sm' | 'md' | 'lg' | 'xl';

  if (isXs) {
    deviceSize = 'xs';
  } else if (isSm) {
    deviceSize = 'sm';
  } else if (isMd) {
    deviceSize = 'md';
  } else if (isLg) {
    deviceSize = 'lg';
  } else if (isXl) {
    deviceSize = 'xl';
  } else {
    deviceSize = 'xl'; // default to 'xl' if none match
  }

  return deviceSize;
};
