import type { FC } from 'react';

import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { useUserAgentDevice } from '@packages/shared/src/hooks/useUserAgentDevice/useUserAgentDevice';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { FilterSettingsFragmentFragmentDoc } from '@packages/gql/generated/shopping/FilterSettingsFragmentFragmentDoc';

import { useSearchFilters } from '../../hooks/useFilters';
import { ErasedFiltersNotification } from '../ErasedFiltersNotification/ErasedFiltersNotification';
import type { FilterSettingsMobileProps } from '../FilterSettingsMobile/FilterSettingsMobile';
import { FilterSettingsMobile } from '../FilterSettingsMobile/FilterSettingsMobile';
import { FilterSettingsDesktop } from '../FilterSettingsDesktop/FilterSettingsDesktop';
import { SelectedFilterValueList } from '../SelectedFilterValueList/SelectedFilterValueList';

/* GraphQL */ `
  fragment FilterSettingsFragment on SearchFilterableAndSortableResult {
    ...UseSearchFiltersFragment
    ...SelectedFilterValueListFragment
    ...FilterSettingsDesktopFragment
    ...FilterSettingsMobileFilterDialogButtonFragment
  }
`;

export type FilterSettingsProps = {
  maskedData: FragmentType<typeof FilterSettingsFragmentFragmentDoc>;
  showRemovedFiltersNotification?: boolean;
  renderCategoryDialogContent: FilterSettingsMobileProps['renderCategoryDialogContent'];
};

/**
 * Responsive component to handle all filter interactions
 * */
export const FilterSettings: FC<FilterSettingsProps> = ({
  maskedData,
  showRemovedFiltersNotification,
  renderCategoryDialogContent,
}) => {
  const userAgentDevice = useUserAgentDevice();
  const { isMobile } = useDeviceType();

  const data = unmask<typeof FilterSettingsFragmentFragmentDoc>(maskedData);

  const { selectedFilterValues, pushFilterChange } = useSearchFilters(data);

  return (
    <>
      <ErasedFiltersNotification
        show={showRemovedFiltersNotification}
        onShow={() => pushFilterChange([])}
      />
      {isMobile || userAgentDevice === 'mobile' ? (
        <FilterSettingsMobile
          maskedData={data}
          selectedFilterValues={selectedFilterValues}
          applyCloseButtonPaddingWorkaround
          onSubmit={pushFilterChange}
          renderCategoryDialogContent={renderCategoryDialogContent}
        />
      ) : (
        <FilterSettingsDesktop
          maskedData={data}
          selectedFilterValues={selectedFilterValues}
          onSubmit={pushFilterChange}
        />
      )}
      <SelectedFilterValueList maskedData={data} showColors />
    </>
  );
};
