import type { FragmentType } from '@packages/gql/generated/shopping';
import type {
  ChargerType,
  PowerSupplyInclusionType,
  SearchChargerType,
  SearchPowerSupplyInclusionType,
} from '@packages/gql/generated/shopping/graphql';
import type { SearchPowerSupplyFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchPowerSupplyFragmentFragmentDoc';
import { unmask } from '@packages/gql/src/betterMasking';
import type { PowerSupplyProps } from '@packages/shared/src/ui/PowerSupply/PowerSupplyProps';

/* GraphQL */ `
  fragment SearchPowerSupplyFragment on SearchPowerSupply {
    maxPower
    minPower
    chargerType
    powerSupplyInclusionType
  }
`;

const chargerTypeMapping: Record<SearchChargerType, ChargerType | undefined> = {
  NORMAL: 'Normal',
  POWER_DELIVERY: 'PowerDelivery',
};

const powerSupplyInclusionTypeMapping: Record<
  SearchPowerSupplyInclusionType,
  PowerSupplyInclusionType | undefined
> = {
  INCLUDED: 'included',
  NOT_INCLUDED: 'notIncluded',
};

export const mapSearchPowerSupplyData = (
  maskedData: FragmentType<typeof SearchPowerSupplyFragmentFragmentDoc>,
): Pick<PowerSupplyProps, 'maxPower' | 'minPower' | 'chargerType' | 'powerSupplyIncluded'> => {
  const searchPowerSupplyData = unmask<typeof SearchPowerSupplyFragmentFragmentDoc>(maskedData);

  return {
    maxPower: searchPowerSupplyData.maxPower,
    minPower: searchPowerSupplyData.minPower,
    chargerType: chargerTypeMapping[searchPowerSupplyData.chargerType] ?? 'Normal',
    powerSupplyIncluded:
      powerSupplyInclusionTypeMapping[searchPowerSupplyData.powerSupplyInclusionType] ??
      'notIncluded',
  };
};
