import { useSearchParams } from 'next/navigation';

import type {
  ProductCardProps,
  ProductCardSpecificProps,
} from '@packages/modules/src/ProductCard/types';
import { mergeQueryParameters } from '@packages/shared/src/utils/mergeQueryParameters/mergeQueryParameters';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { searchParamsToObject } from '@packages/shared/src/utils/searchParamsToObject/searchParamsToObject';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import { type StandardFlagType } from '@packages/gql/generated/shopping/graphql';
import type { SearchProductCardFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchProductCardFragmentFragmentDoc';
import type { SearchProductCardFullVariationGroupFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchProductCardFullVariationGroupFragmentFragmentDoc';
import type { FlagProps } from '@packages/shared/src/components/Flag/Flag';

import { useProductCardPrice } from '../../hooks/useProductCardPrice';
import type { DesktopDisplayMode } from '../../types';
import { mapProductCardAvailabilities } from '../../utils/mapProductCardAvailabilities';
import { encodePage } from '../../queryEncoding';
import { useHasInstallmentsPayment } from './useHasInstallmentsPayment';
import { mapSearchPowerSupplyData } from './mapSearchPowerSupplyData';
import { useMappedEfficiencyFlags } from '../../hooks/useMappedEfficiencyFlags';

/* GraphQL */ `
  fragment SearchProductCardFullVariationGroupFragment on CompleteVariationGroup {
    groupName
    image
    imageForHighlight
    sku # only for tracking?
    articleNumber # only for tracking
    href
    price {
      ...SearchProductPriceFragment
    }
    availability {
      ...ProductAvailabilityOverlayFragment
    }
    customFlag {
      # description
      image
    }
    standardFlags {
      type
      text
    }
  }
`;

/* GraphQL */ `
  fragment SearchProductCardFragment on StylePreviewItem {
    styleId # needed?
    styleName
    categoryName # only for tracking
    # isHighlight
    brand {
      name
    }
    efficiencyFlags {
      ...MapEfficiencyFlagsFragment
    }
    ratings {
      averageValue
      count
    }
    sellingPoints
    primaryVariationGroup {
      sku
      searchPowerSupply {
        ...SearchPowerSupplyFragment
      }
      ...SearchProductCardFullVariationGroupFragment
      ...SearchProductCardClickTrackingVariationGroupFragment
      ...SearchProductCardGlycerinTrackingVariationGroupFragment
    }
    variationGroups {
      groupName
      icon {
        image
      }
    }
    ...SearchProductCardImpressionTrackingItemFragment
    ...SearchProductCardClickTrackingItemFragment
    ...SearchProductCardGlycerinTrackingItemFragment
  }
`;

const getVariationImage = (
  imageUrl: string,
  highlightArticleImageUrl: string | null,
  displayMode: DesktopDisplayMode | undefined,
  isDesktop: boolean,
): string => {
  // if there is no highlight image url, show normal image
  if (!highlightArticleImageUrl) return imageUrl;

  // show regular image if product card is in "Mobile Listenansicht" or "Tablet Listenansicht" mode
  return !isDesktop && displayMode === 'moreInformation' ? imageUrl : highlightArticleImageUrl;
};

const standardFlagTypeMap: Record<StandardFlagType, FlagProps['type']> = {
  DEFAULT: 'neutral',
  SECONDARY: 'secondary',
  SUSTAINABILITY: 'sustainability',
};

export const useVariationDataForProductCard = (
  maskedStyleItem: FragmentType<typeof SearchProductCardFragmentFragmentDoc>,
  activeVariationIndex: number,
  maskedVariation: FragmentType<typeof SearchProductCardFullVariationGroupFragmentFragmentDoc>,
  displayMode: DesktopDisplayMode,
  pageNumber?: number,
  useUnitPriceSpacer?: boolean,
): ProductCardSpecificProps => {
  const styleItem = unmask<typeof SearchProductCardFragmentFragmentDoc>(maskedStyleItem);
  const fullVariation =
    unmask<typeof SearchProductCardFullVariationGroupFragmentFragmentDoc>(maskedVariation);

  const searchParams = useSearchParams();
  const query = searchParamsToObject(searchParams);

  const hasInstallmentsPayment = useHasInstallmentsPayment();

  const { isDesktop } = useDeviceType();

  const productLink = mergeQueryParameters(fullVariation.href, {
    ...query,
    // Page number is included in the query, but the semantic meaning is different for infinite scrolling.
    // The page number included in the query is the "last loaded" page number, while the `pageNumber` from the props is the page the card is located in
    // This is important for back navigation, to load the correct page of products
    ...(pageNumber && encodePage(pageNumber)),
  });

  const customFlag = fullVariation.customFlag
    ? [{ type: 'custom' as const, src: fullVariation.customFlag.image }]
    : [];
  const standardFlags = fullVariation.standardFlags.map(({ type, text }) => ({
    type: standardFlagTypeMap[type],
    text,
  }));
  const flags = ([] as ProductCardProps['flags'])?.concat(customFlag)?.concat(standardFlags);

  const efficiencyFlags = useMappedEfficiencyFlags(styleItem.efficiencyFlags);

  return {
    sku: fullVariation.sku,
    brand: styleItem.brand.name,
    title: styleItem.styleName,
    colors: styleItem.variationGroups.map((variationGroup, index) => ({
      name: variationGroup.groupName ?? '',
      image: variationGroup.icon?.image,
      active: index === activeVariationIndex,
    })),
    availability: mapProductCardAvailabilities(fullVariation.availability),
    variationName: fullVariation.groupName ?? undefined,
    productLink,
    image: getVariationImage(
      fullVariation.image,
      fullVariation.imageForHighlight,
      displayMode,
      isDesktop,
    ),
    price: {
      ...useProductCardPrice(fullVariation.price, { useUnitPriceSpacer }),
      hasInstallmentsPayment,
    },
    flags,
    efficiencyFlags,
    additionalInfo: displayMode === 'moreInformation' ? styleItem.sellingPoints : undefined,
    rating: styleItem.ratings
      ? { avgRating: styleItem.ratings.averageValue, reviewCount: styleItem.ratings.count }
      : undefined,
    ...(styleItem.primaryVariationGroup && styleItem.primaryVariationGroup.searchPowerSupply
      ? {
          powerSupplyProps: {
            ...mapSearchPowerSupplyData(styleItem.primaryVariationGroup.searchPowerSupply),
            asCard: false,
            showPowerSupplyMessage: false,
          },
        }
      : {}),
  };
};
