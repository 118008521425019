'use client';

import type { FC, ReactNode } from 'react';
import { useDebugMode } from '../../hooks/useDebugMode/useDebugMode';

export const OnlyInDebugMode: FC<{ children: ReactNode }> = ({ children }) => {
  const { debugModeActive } = useDebugMode();

  if (!debugModeActive) return null;

  return children;
};
