import { useSearchParams } from 'next/navigation';
import { defineMessage, useIntl } from 'react-intl';

import { searchParamsToObject } from '@packages/shared/src/utils/searchParamsToObject/searchParamsToObject';
import { mergeQueryParameters } from '@packages/shared/src/utils/mergeQueryParameters/mergeQueryParameters';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { UseCategoryDataForFilterNavigationMobileFragmentFragment } from '@packages/gql/generated/shopping/graphql';
import type { UseCategoryDataForFilterNavigationMobileFragmentFragmentDoc } from '@packages/gql/generated/shopping/UseCategoryDataForFilterNavigationMobileFragmentFragmentDoc';
import type { UseCategoryDataForFilterNavigationMobileWorkaroundFragmentFragmentDoc } from '@packages/gql/generated/shopping/UseCategoryDataForFilterNavigationMobileWorkaroundFragmentFragmentDoc';

import { extractDisplayMode, extractOrder, extractSelectedFilterValues } from '../../queryEncoding';
import type { CategoryFilterItem } from '../CategoryFilterStandalone/CategoryFilterStandalone';

/* GraphQL */ `
  fragment UseCategoryDataForFilterNavigationMobileFragment on FlatCategoryItem {
    id
    name
    pathName
    level
    isSelectedExact
    isSelectedIndirectly
    parentId
    hasChildren
  }
`;

/* GraphQL */ `
  fragment UseCategoryDataForFilterNavigationMobileWorkaroundFragment on CategoryResult {
    currentTopLevelCategory {
      ...UseCategoryDataForFilterNavigationMobileFragment
    }
  }
`;

const allCategoriesMessage = defineMessage({
  id: 'search.filters.category.allCategories',
  defaultMessage: 'Alle Kategorien',
});

export const useCategoryDataForFilterNavigationMobile = (
  maskedItems: FragmentType<typeof UseCategoryDataForFilterNavigationMobileFragmentFragmentDoc>[],
  maskedResult: FragmentType<
    typeof UseCategoryDataForFilterNavigationMobileWorkaroundFragmentFragmentDoc
  >,
): CategoryFilterItem[] => {
  const searchParams = useSearchParams();
  const query = searchParamsToObject(searchParams);

  const { formatMessage } = useIntl();

  const items = unmask<typeof UseCategoryDataForFilterNavigationMobileFragmentFragmentDoc>(
    maskedItems,
  ).filter((x) => x.level >= 2); // forward compatibility for future changes, where `root` (really the L1 category) will be included in the categories itself and not provided separately
  const result =
    unmask<typeof UseCategoryDataForFilterNavigationMobileWorkaroundFragmentFragmentDoc>(
      maskedResult,
    );

  const selectedParentItems = items.filter((x) => x.isSelectedIndirectly) || [];

  // find the selected category
  const activeCategory = items.find((x) => x.isSelectedExact) || null;

  let childrenItems: UseCategoryDataForFilterNavigationMobileFragmentFragment[] = [];

  if (activeCategory !== null) {
    const parentIdOfActiveCategory = items.find((x) => x.id === activeCategory.parentId)?.id;

    // check if current category has children otherwise use siblings from parent
    childrenItems = activeCategory.hasChildren
      ? items.filter((x) => x.parentId === activeCategory.id)
      : items.filter((x) => x.parentId === parentIdOfActiveCategory && !x.isSelectedExact);
  }

  // bundle all categories together in correct order
  const categories = [
    // manually add link to home
    {
      id: '0',
      name: formatMessage(allCategoriesMessage),
      level: 0,
      isSelectedIndirectly: true,
      isSelectedExact: false,
      pathName: '/',
    },
    unmask<typeof UseCategoryDataForFilterNavigationMobileFragmentFragmentDoc>(
      result.currentTopLevelCategory,
    ),
    ...selectedParentItems,
    ...(activeCategory !== null ? [activeCategory] : []),
    ...childrenItems,
  ];

  return categories.map(
    ({ id, name, level, isSelectedIndirectly, isSelectedExact, pathName = '' }) => ({
      id,
      name,
      level,
      // only keep user settings in href for child categories
      href: isSelectedIndirectly
        ? pathName
        : mergeQueryParameters(pathName, {
            ...extractDisplayMode(query),
            ...extractOrder(query),
            ...extractSelectedFilterValues(query),
          }),
      pathName,
      isSelected: Boolean(isSelectedIndirectly || isSelectedExact),
      isSelectedExact: Boolean(isSelectedExact),
    }),
  );
};
