'use client';

import type { FC } from 'react';

import { Box, Grid, ErrorBoundary } from '@packages/shared';
import { ContentExternal } from '@packages/cms-components/src/modules/ContentExternal/ContentExternal';
import type { CmsData } from '@packages/cms-components/interfaces';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { CategoryPageBrandOverviewFragmentFragmentDoc } from '@packages/gql/generated/shopping/CategoryPageBrandOverviewFragmentFragmentDoc';
import type { ProductListSeoFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductListSeoFragmentFragmentDoc';

import { BrandList } from '../BrandList/BrandList';
import { ProductListSeo } from '../ProductListSeo/ProductListSeo';
import { SearchKimRating } from '../SearchKimRating/SearchKimRating';

/* GraphQL */ `
  fragment CategoryPageBrandOverviewFragment on BrandsResult {
    ...SearchKimRatingFragment
    ...SearchKimRatingCategoryPageFragment

    brands {
      ...SearchBrandListFragment
    }
  }
`;

export type CategoryPageBrandOverviewProps = {
  cmsContent?: CmsData | null;
  maskedSeoResponse?: FragmentType<typeof ProductListSeoFragmentFragmentDoc>;
  maskedResult: FragmentType<typeof CategoryPageBrandOverviewFragmentFragmentDoc>;
  referrer?: string;
};

/**
 * Page content for Brands page
 * */
export const CategoryPageBrandOverview: FC<CategoryPageBrandOverviewProps> = ({
  cmsContent,
  maskedSeoResponse,
  maskedResult,
  referrer,
}) => {
  const data = unmask<typeof CategoryPageBrandOverviewFragmentFragmentDoc>(maskedResult);

  return (
    <Grid container spacing={0} padding={0}>
      <Grid item>
        {cmsContent && (
          <ErrorBoundary>
            <ContentExternal data={cmsContent} />
          </ErrorBoundary>
        )}
        <Box
          sx={{
            padding: (theme) => theme.spacing(1),
          }}
        >
          <BrandList maskedBrands={data.brands} />
        </Box>

        <ErrorBoundary>
          <SearchKimRating
            maskedData={data}
            maskedCategoryPageData={data}
            searchPageType="MBA"
            templateParams={{ x17: referrer ?? 'x' }}
          />
        </ErrorBoundary>

        {maskedSeoResponse && <ProductListSeo maskedData={maskedSeoResponse} />}
      </Grid>
    </Grid>
  );
};
