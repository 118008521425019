import { useRef, type FC } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'next/navigation';

import { ProductCard } from '@packages/modules/src/ProductCard/ProductCard';
import type { ProductCardProps } from '@packages/modules/src/ProductCard/types';
import { Box } from '@packages/shared';
import { rememberPageData } from '@packages/shared/src/hooks/useScrollRestoration/useScrollRestoration';
import { useUrl } from '@packages/shared/src/hooks/useUrl/useUrl';
import { mergeQueryParameters } from '@packages/shared/src/utils/mergeQueryParameters/mergeQueryParameters';
import { searchParamsToObject } from '@packages/shared/src/utils/searchParamsToObject/searchParamsToObject';
import { usePushQueryChange } from '@packages/shared/src/hooks/usePushQueryChange/usePushQueryChange';
import { GlycerinPlacement } from '@packages/tracking/src/types/enums';
import { type GlycerinAdClickPayload } from '@packages/tracking/src/types/payloads';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { CriteoProductCardFragmentFragmentDoc } from '@packages/gql/generated/shopping/CriteoProductCardFragmentFragmentDoc';

import { CriteoBeaconImage } from '../CriteoBeaconImage/CriteoBeaconImage';
import { mapProductCardAvailabilities } from '../../utils/mapProductCardAvailabilities';
import { useProductCardPrice } from '../../hooks/useProductCardPrice';
import { encodePage } from '../../queryEncoding';
import { useOnFirstBecameVisible } from '../../hooks/useOnFirstBecameVisible';
import { useMappedEfficiencyFlags } from '../../hooks/useMappedEfficiencyFlags';

/* GraphQL */ `
  fragment CriteoProductCardFragment on SponsoredStylePreviewItem {
    styleId # needed?
    styleName
    categoryName # tracking for criteo the same as regular cards?
    brand {
      name
      image
    }
    efficiencyFlags {
      ...MapEfficiencyFlagsFragment
    }
    sellingPoints
    primaryVariationGroup {
      groupName
      sku
      image
      href
      price {
        ...SearchProductPriceFragment
      }
      availability {
        ...ProductAvailabilityOverlayFragment
      }
    }
    tracking {
      onLoad
      onView
    }
  }
`;

export type CriteoProductCardProps = {
  maskedData: FragmentType<typeof CriteoProductCardFragmentFragmentDoc>;
  pageNumber?: number;
  showAdditionalInfo?: boolean;
  onFirstBecameVisible?: () => void;
};

const sponsoredFlagText = defineMessage({
  id: 'search.productList.sponsored.flag',
  defaultMessage: 'Gesponsert',
});

// TODO: make a common function in utils to handle card props for visible variation
// here and also in 'components/SearchProductCard/SearchProductCard.tsx:L162'
const useCriteoProductCardProps = (
  maskedData: FragmentType<typeof CriteoProductCardFragmentFragmentDoc>,
  showAdditionalInfo?: boolean,
): ProductCardProps => {
  const searchParams = useSearchParams();
  const query = searchParamsToObject(searchParams);

  const data = unmask<typeof CriteoProductCardFragmentFragmentDoc>(maskedData);

  const variationGroup = data.primaryVariationGroup;

  const productUrl = mergeQueryParameters(variationGroup.href, query);

  const clickTrackingProps: GlycerinAdClickPayload = {
    adId: 'criteo',
    adType: 'criteo',
    category: 'criteo-card-click',
    target: productUrl,
    label: `${data.brand.name} ${data.styleName}`,
    placement: GlycerinPlacement.LISTING,
  };

  const efficiencyFlags = useMappedEfficiencyFlags(data.efficiencyFlags);

  return {
    sku: variationGroup.sku,
    brand: data.brand.name,
    title: data.styleName,
    colors: [],
    availability: mapProductCardAvailabilities(variationGroup.availability),
    variationName: variationGroup.groupName ?? undefined,
    productLink: productUrl,
    image: variationGroup.image,
    efficiencyFlags,
    additionalInfo: showAdditionalInfo ? data.sellingPoints : undefined,
    price: useProductCardPrice(variationGroup.price),
    clickTrackingProps,
  };
};

/**
 * ProductCard component used to render sponsored criteo products
 * */
export const CriteoProductCard: FC<CriteoProductCardProps> = ({
  maskedData,
  pageNumber,
  showAdditionalInfo,
  onFirstBecameVisible,
}) => {
  const item = unmask<typeof CriteoProductCardFragmentFragmentDoc>(maskedData);

  const { formatMessage } = useIntl();

  const productCardProps = useCriteoProductCardProps(maskedData, showAdditionalInfo);

  const url = useUrl(true);

  const { pushQueryChange } = usePushQueryChange({ trailingSlash: true });

  const handleClick = () => {
    const queryChange = encodePage(pageNumber);
    pushQueryChange(queryChange, { shallow: true });

    const modifiedUrl = mergeQueryParameters(url, queryChange);
    rememberPageData(item.styleId, modifiedUrl);
  };

  const wrapper = useRef<HTMLDivElement>(null);
  useOnFirstBecameVisible(wrapper, onFirstBecameVisible);

  return (
    <Box ref={wrapper} id={item.styleId} height="100%">
      {item.tracking.onLoad && <CriteoBeaconImage src={item.tracking.onLoad} name="onLoad" />}
      {item.tracking.onView && <CriteoBeaconImage src={item.tracking.onView} name="onView" />}
      <ProductCard
        {...productCardProps}
        onProductLinkActionAreaClick={handleClick}
        flags={[{ text: formatMessage(sponsoredFlagText), type: 'neutral' }]}
        isSponsored
        priority
        shrinkToFit
      />
    </Box>
  );
};
