import { useCurrentMuiBreakpoint } from '@packages/shared/src/hooks/useCurrentMuiBreakpoint/useCurrentMuiBreakpoint';

export const useCardRenderingCounts = (
  columns: { xs: number; sm: number; md: number; lg: number },
  hasSponsoredProducts?: boolean,
) => {
  const currentBreakpoint = useCurrentMuiBreakpoint();

  const finalBreakpoint = currentBreakpoint === 'xl' ? 'lg' : currentBreakpoint;

  const productsPerRow = columns[finalBreakpoint];

  // always pre-render 2 full rows of products, including sponsoredProducts if available
  const sponsoredProductCount = hasSponsoredProducts ? productsPerRow : 0;
  const prerenderedProductCount = 2 * productsPerRow - sponsoredProductCount;

  return {
    sponsoredProductCount,
    prerenderedProductCount,
  };
};
