import type { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Box } from '@packages/shared';
import { Select } from '@packages/shared/src/components/Select';
import { ArrowMediumDown32 } from '@packages/themes/icons';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { DisplaySettingsDesktopFragmentFragmentDoc } from '@packages/gql/generated/shopping/DisplaySettingsDesktopFragmentFragmentDoc';

import { usePaging } from '../../hooks/usePaging';
import { useDisplayMode } from '../../hooks/useDisplayMode';
import type { PagingMode, DesktopDisplayMode } from '../../types';
import { DisplaySettingsDesktopPagination } from '../DisplaySettingsDesktopPagination/DisplaySettingsDesktopPagination';
import { useFeatureGroup } from '../../utils/featureFlags/useFeatureGroup';
import { newInfiniteScrolling } from '../../activeFeatureFlags';
import { SortingOptionsSelect } from '../SortingOptionsSelect/SortingOptionsSelect';

/* GraphQL */ `
  fragment DisplaySettingsDesktopFragment on SearchProductResult {
    ...UseDisplayModeFragment
    ...UsePagingFragment
    ...SortingOptionsSelectFragment
    ...DisplaySettingsDesktopPaginationFragment
    availableSortingOrders {
      key
      displayName
    }
  }
`;

export type DisplaySettingsDesktopProps = {
  maskedData: FragmentType<typeof DisplaySettingsDesktopFragmentFragmentDoc>;
};

const messages = defineMessages({
  displayModeLabel: {
    id: 'search.productList.displaySettings.displayMode.label',
    defaultMessage: 'Ansicht wechseln',
  },
  displayModeCards: {
    id: 'search.productList.displaySettings.displayModes.cards',
    defaultMessage: 'Kleine Bilder',
  },
  displayModeLargeCards: {
    id: 'search.productList.displaySettings.displayModes.largeCards',
    defaultMessage: 'Große Bilder',
  },
  displayModeMoreInformation: {
    id: 'search.productList.displaySettings.displayModes.moreInformation',
    defaultMessage: 'Mehr Informationen',
  },
  pagingModeInfinite: {
    id: 'search.productList.displaySettings.pagingMode.infinite',
    defaultMessage: 'Alle anzeigen',
  },
  pagingModeLimited: {
    id: 'search.productList.displaySettings.pagingMode.limited',
    defaultMessage: '72 Artikel pro Seite',
  },
});

/**
 * Component to allow the user to change the display settings for the product list
 * */
export const DisplaySettingsDesktop: FC<DisplaySettingsDesktopProps> = ({ maskedData }) => {
  const data = unmask<typeof DisplaySettingsDesktopFragmentFragmentDoc>(maskedData);
  const { formatMessage } = useIntl();
  const { pagingMode, pageCount, pushPagingMode } = usePaging(data);
  const { displayMode, pushDisplayModeChange } = useDisplayMode(data);
  const infiniteScrollingGroup = useFeatureGroup(newInfiniteScrolling);

  const displayModes = [
    { value: 'cards', label: formatMessage(messages.displayModeCards) },
    { value: 'largeCards', label: formatMessage(messages.displayModeLargeCards) },
    { value: 'moreInformation', label: formatMessage(messages.displayModeMoreInformation) },
  ];

  const pagingModeItems = [
    { value: 'pagination', label: formatMessage(messages.pagingModeLimited) },
    { value: 'infinite', label: formatMessage(messages.pagingModeInfinite) },
  ];

  return (
    <Box
      sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 2, alignItems: 'center' }}
    >
      <Select
        IconComponent={(props) => <ArrowMediumDown32 {...props} />}
        label={formatMessage(messages.displayModeLabel)}
        items={displayModes}
        value={displayMode}
        onChange={(event) => {
          pushDisplayModeChange(event.target.value as DesktopDisplayMode);
        }}
        MenuProps={{ disableScrollLock: true }}
      />
      {infiniteScrollingGroup === 'optionalInfiniteWithScrollBasedLoading' && (
        <Select
          IconComponent={(props) => <ArrowMediumDown32 {...props} />}
          label=""
          items={pagingModeItems}
          value={pagingMode}
          onChange={(event) => {
            pushPagingMode(event.target.value as PagingMode);
          }}
          MenuProps={{ disableScrollLock: true }}
        />
      )}
      <SortingOptionsSelect maskedData={data} />
      {infiniteScrollingGroup === 'optionalInfiniteWithScrollBasedLoading' &&
        pagingMode !== 'infinite' &&
        pageCount > 1 && <DisplaySettingsDesktopPagination maskedData={data} />}
    </Box>
  );
};
